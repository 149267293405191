import './App.css';
import img3 from './images/3.jpg'
import img2 from './images/2.jpg'

import img11 from './images/1 (1).jpg'
import img12 from './images/1 (2).jpg'
import img13 from './images/1 (3).jpg'
import img14 from './images/1 (4).jpg'
import img15 from './images/1 (5).jpg'
import img16 from './images/1 (6).jpg'
import img17 from './images/1 (7).jpg'
import img18 from './images/1 (8).jpg'
import img19 from './images/1 (9).jpg'
import img20 from './images/1 (10).jpg'
import img21 from './images/1 (11).jpg'
import img22 from './images/1 (12).jpg'
import img23 from './images/1 (13).jpg'
import img24 from './images/1 (14).jpg'
import img25 from './images/1 (15).jpg'
import img26 from './images/1 (16).jpg'
import img27 from './images/1 (17).jpg'
import img28 from './images/1 (18).jpg'
import img29 from './images/1 (19).jpg'
import img30 from './images/1 (20).jpg'
import img31 from './images/1 (21).jpg'
import img32 from './images/1 (22).jpg'
import img33 from './images/1 (23).jpg'
import img34 from './images/1.jpg'


import ev1 from './images/evolution/xx.jpg'
import ev2 from './images/evolution/x1.jpg'
import ev3 from './images/evolution/x2.jpg'
import ev4 from './images/evolution/x3.jpg'
import ev5 from './images/evolution/x4.jpg'
import ev6 from './images/evolution/x5.jpg'
import ev7 from './images/evolution/x7.jpg'


import vid1 from './videos/x (1).mp4'
import vid2 from './videos/x (2).mp4'
import vid3 from './videos/x (3).mp4'
import vid4 from './videos/x (4).mp4'
import vid5 from './videos/x (5).mp4'
import vid6 from './videos/x (6).mp4'
import vid7 from './videos/x (7).mp4'
import vid8 from './videos/x (8).mp4'
import vid9 from './videos/x (9).mp4'
import vid10 from './videos/x (10).mp4'
import vid11 from './videos/x (11).mp4'
import vid12 from './videos/x (12).mp4'
import vid13 from './videos/x (13).mp4'
import vid14 from './videos/x (14).mp4'
import vid15 from './videos/x (15).mp4'

import fond from './images/fond.jpg'
import f1 from './images/f1.jpg'

function App() {
  return (
    <div className="App">
      <header>
        <div className='logo'>Ecole DTN</div>
        <div className='nav'>
          <ul>
            <li>Accueil</li>
            <li>A propos</li>
            <li>Galerie</li>
            <li>Contact</li>
          </ul>
        </div>
      </header>

      <main>
        <div className='accueil'>
          <div className='text'>
            <p className='p-text'>Ecole DTN</p>
            <p className='s-text'>De Boudo Senkoura</p>
          </div>
        </div>

        <div className='apropos'>
          <span className='liste'>A propos</span>
          <div className='text-img'>
            <div className='text'>
            Un jeune conscient des lacunes dans son village natal, où l'accès à l'éducation était un luxe, caressa le rêve ambitieux de construire une école primaire pour les enfants de sa communauté. Malgré les doutes et les défis, il ne pouvait ignorer le besoin criant d'un lieu où les jeunes esprits pourraient s'épanouir.
            </div>
            <div className='img'>
              <img src={ev1} alt="" />
            </div>
          </div>
          <div className='text-img'>
            <div className='img'>
              <img src={ev2} alt="" />
            </div>
            <div className='text'>
            Jeune homme a investi le peu qu'il avait et, grâce à la main-d'œuvre locale, il a commencé à mettre en œuvre son plan. Il parcourut le village, discutant avec les aînés pour élaborer une stratégie efficace. Ensemble, ils ont imaginé comment ils pourraient construire l'école avec les matériaux disponibles localement et le travail bénévole de la communauté.
            </div>
          </div>
          <div className='text-img'>
            <div className='text'>
            Au fil du temps, les fondations prirent forme et les murs de l'école commencèrent à s'élever vers le ciel. Jeune homme et ses compagnons de travail avaient construit quatre salles de classe spacieuses, remplies de promesses et de possibilités. Chaque mur était imprégné du désir ardent d'apprendre et chaque fenêtre laissait entrer la lumière de l'espoir.
            </div>
            <div className='img'>
              <img src={ev3} alt="" />
            </div>
          </div>
          <div className='text-img'>
            <div className='img'>
              <img src={ev7} alt="" />
            </div>
            <div className='text'>
            Enfin, le jour tant attendu arriva. L'école était prête à accueillir ses premiers élèves. Bien que modeste dans sa conception, elle représentait un trésor inestimable pour les enfants du village. Les sourires radieux des enfants franchissant ses portes étaient une récompense plus grande que tout trésor matériel
            </div>
          </div>
        </div>

        <div className='galerie'>
          <span className='liste'> Galerie</span>
          <div className='flex-galerie'>
            <img src={img11} alt="image1" />
            <img src={img12} alt="image1" />
            <img src={img13} alt="image1" />
            <img src={img14} alt="image1" />
            <img src={img15} alt="image1" />
            <img src={img16} alt="image1" />
            <img src={img17} alt="image1" />
            <img src={img18} alt="image1" />
            <img src={img19} alt="image1" />
            <img src={img20} alt="image1" />
            <img src={img21} alt="image1" />
            <img src={img22} alt="image1" />
            <img src={img23} alt="image1" />
            <img src={img24} alt="image1" />
            <img src={img25} alt="image1" />
            <img src={img26} alt="image1" />
            <img src={img27} alt="image1" />
            <img src={img28} alt="image1" />
            <img src={img29} alt="image1" />
            <img src={img30} alt="image1" />
            <img src={img31} alt="image1" />
            <img src={img32} alt="image1" />
            <img src={img33} alt="image1" />
            <img src={img34} alt="image1" />
          </div>
        </div>
        <div className='me'>
        </div>

        <div className='text-img-box'>
          <div className='text-img'>
            <img className='f1' src={f1} alt="me" />
            <div className='text'>
            l'école construite par Jeune homme ne serait pas seulement un bâtiment en pierre et en boue, mais un symbole de l'espoir et du changement positif qu'une petite communauté peut accomplir lorsque ses membres travaillent ensemble vers un objectif commun.
            </div>
          </div>
        </div>

        
        <div className='galerie'>
          <span className='liste'> Videos</span>
          <div className='flex-galerie'>
            <video src={vid2} controls></video>
            <video src={vid1} controls ></video>
            <video src={vid3} controls></video>
            <video src={vid4} controls></video>
            <video src={vid5} controls></video>
            <video src={vid6} controls></video>
            <video src={vid7} controls></video>
            <video src={vid8} controls></video>
            <video src={vid9} controls></video>
            <video src={vid10} controls></video>

          </div>
        </div>

        <div className='contact'>
          <span className='liste mb'>
            Contact
          </span>
        <div className='social'>
          <div> Facebook </div>
          <div> WhatsApp </div>
        </div>
        </div>
      </main>

    </div>
  );
}

export default App;
